<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "error_already_assigned": "Das {account} wurde bereits verknüpft. Bitte geben Sie andere Daten ein.",
      "error_already_assigned_ad_account": "Ihr »{provider}« wurde bereits mit einem {account} verknüpft. Bitte wiederholen Sie die Anmeldung und wählen Sie gegebenfalls ein anderes »{provider}«.",
      "error_connected_master": "Das {account} kann nicht mit Ihrem »{provider}« verknüpft werden, da es nicht zur Ihrer Firma gehört. Bitte geben Sie andere Daten ein.",
      "error_credentials": "Anmeldung fehlgeschlagen. Bitte prüfen Sie Benutzername und Passwort.",
      "error_not_allowed_for_master": "Ipxx Master Konten dürfen nicht mit Ihrem »{provider}« verknüpft werden.",
      "intro": "Ihr bisheriges {account} muss noch mit Ihrem »{provider}« verknüpft werden.<br>Geben Sie hierzu einmalig den Benutzernamen und Passwort von Ihrem {account} unten ein und bestätigen Sie mit »{submitButtonText}«.",
      "intro_hint_register": "Sollten Sie noch nicht registriert sein, so klicken Sie bitte auf »{registerLinkText}«.",
      "invalid_feedback_required": "Bitte geben Sie gültige Daten ein.",
      "password": "Passwort",
      "password_forgotten": "Passwort vergessen?",
      "register": "Neu bei uns? Hier registrieren",
      "success_message": "<strong>Es wurde eine E-Mail zum Zurücksetzen des Passwortes für den Benutzernamen »{username}« gesendet.</strong><br><br>Wir haben eine Nachricht an »{email}« gesendet.<br>Folgen Sie den Anweisungen in der E-Mail, um Ihr Passwort zurückzusetzen.<br>Falls Sie die E-Mail nicht erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner.<br>Wenn Sie Hilfe benötigen, zögern Sie nicht, uns unter {support_email} zu kontaktieren!",
      "username": "Benutzername"
  },
    "en": {
      "error_already_assigned": "The {account} has already been linked. Please enter other data.",
      "error_already_assigned_ad_account": "Your ‘{provider}’ has already been linked to a {account}. Please repeat the login and choose another ‘{provider}’ if necessary.",
      "error_connected_master": "The {account} cannot be linked to your ‘{provider}’ because it does not belong to your company. Please enter other data.",
      "error_credentials": "Login failed. Please check username and password.",
      "error_not_allowed_for_master": "Ipxx Master accounts may not be linked with your ‘{provider}’.",
      "intro": "Your previous {account} must still be linked to your ‘{provider}’. Enter the access data of your {account} below and confirm with ‘{submitButtonText}’.",
      "intro_hint_register": "If you are not registered yet, please click ‘{registerLinkText}’.",
      "invalid_feedback_required": "This field is required.",
      "password": "Password",
      "password_forgotten": "Forgotten password?",
      "register": "Don't have an account? Register here",
      "success_message": "<strong>An email was sent to reset your password for username ‘{username}’.</strong><br><br>We have sent a message to ‘{email}’.<br>Follow the instructions in the email to reset your password.<br>If you did not receive the email, please check your spam folder as well.<br>If you need any help, don’t hesitate to contact us at {support_email} !",
      "username": "Username"
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div
    class="ipxx-assign-account ipxx-content px-5"
  >
    <h2>{{ $t('titles.assign_account') }}</h2>
    <b-row
      v-show="!isEmailSent && !showRestartAd"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col">
        <p
          v-html="$t('intro', {
            account: $t('corporate.mypm_account'),
            provider: externalAuthProviderName,
            submitButtonText: submitButtonText
          })"
        />
        <p v-if="registrationEnabled">
          {{ $t('intro_hint_register', { registerLinkText }) }}
        </p>
      </div>
    </b-row>
    <b-row
      v-show="errorCode"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          variant="danger"
          data-cy="errorMessage"
          v-html="errorMessage"
        />
      </div>
    </b-row>
    <b-row
      v-show="isEmailSent"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          variant="success"
          data-cy="successMessage"
          v-html="$t('success_message', {
            email: emailAddress,
            support_email: $t('corporate.support_email'),
            username: requestedEmailForUser
          })"
        />
      </div>
    </b-row>
    <b-form
      v-if="!showRestartAd"
      class="text-left my-4"
      @submit.prevent="assignAccount"
    >
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          <b-form-group
            label-for="inputUsername"
            :label="`${$t('username')}*`"
            :invalid-feedback="$t('invalid_feedback_required')"
          >
            <b-form-input
              id="inputUsername"
              v-model="userData.username"
              :state="!$v.userData.username.$error && null"
              autocomplete="username"
              tabindex="1"
              trim
              @input="$v.userData.username.$touch()"
            />
          </b-form-group>
        </div>
      </b-row>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          <b-form-group
            label-for="inputPassword"
            :invalid-feedback="$t('invalid_feedback_required')"
          >
            <template #label>
              {{ $t('password') }}*
              <b-btn
                variant="link"
                class="float-right p-0"
                data-cy="passwordForgotLink"
                tabindex="4"
                @click="forgotPassword"
              >
                {{ $t('password_forgotten') }}
              </b-btn>
            </template>
            <b-form-input
              id="inputPassword"
              v-model="userData.password"
              :state="!$v.userData.password.$error && null"
              autocomplete="current-password"
              tabindex="2"
              trim
              type="password"
              @input="$v.userData.password.$touch()"
            />
          </b-form-group>
        </div>
      </b-row>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          <b-btn
            v-if="registrationEnabled"
            variant="link"
            data-cy="registerLink"
            class="float-left px-0"
            tabindex="5"
            @click="registerAccount"
          >
            {{ registerLinkText }}
          </b-btn>
          <b-btn
            type="submit"
            variant="primary"
            class="float-right"
            data-cy="assignSubmitBtn"
            tabindex="3"
          >
            {{ submitButtonText }}
          </b-btn>
        </div>
      </b-row>
    </b-form>
    <div v-else>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          <b-btn
            variant="primary"
            class="float-right"
            data-cy="restartAdBtn"
            @click="restartAdLogin"
          >
            {{ $t('labels.retry') }}
          </b-btn>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import restartAdMixin from '@/components/mixins/restartAdMixin';

export default {
  name: 'AssignAccountForm',
  mixins: [validationMixin, restartAdMixin],
  props: {
    emailAddress: {
      type: String,
      default: '',
    },
    isEmailSent: Boolean,
    requestedEmailForUser: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errorCode: null,
      showRestartAd: false,
      userData: {
        password: '',
        username: '',
      },
    };
  },
  computed: {
    errorMessage() {
      if (!this.errorCode) {
        return '';
      }
      switch (this.errorCode) {
        case this.$config.errors.account_assign_invalid_credentials:
          return this.$t('error_credentials');
        case this.$config.errors.account_assign_bound_to_other:
          return this.$t('error_connected_master',
            {
              account: this.$t('corporate.mypm_account'),
              provider: this.externalAuthProviderName,
            });
        case this.$config.errors.account_assign_not_allowed_for_master:
          return this.$t('error_not_allowed_for_master',
            {
              provider: this.externalAuthProviderName,
            });
        case this.$config.errors.account_assign_already_assigned:
          return this.$t('error_already_assigned',
            {
              account: this.$t('corporate.mypm_account'),
            });
        case this.$config.errors.account_assign_external_auth_disabled:
          return this.$t('errors.error_external_auth_disabled');
        case this.$config.errors.account_assign_external_id_already_assigned:
          return this.$t('error_already_assigned_ad_account',
            {
              account: this.$t('corporate.mypm_account'),
              provider: this.externalAuthProviderName,
            });
        default:
          return this.$t('errors.unknown_error');
      }
    },
    /**
     * Returns the external authentication provider account name.
     */
    externalAuthProviderName() {
      const { authProviderId } = this.$route.params;
      if (authProviderId === this.$config.providers.azure) {
        return this.$t('providers.azure_account');
      }
      return this.$t('providers.default_account');
    },
    /**
     * Returns the translated register link text.
     */
    registerLinkText() {
      return this.$t('register');
    },
    /**
     * Checks if the registration is enabled in the current environment.
     */
    registrationEnabled() {
      return !!process.env.VUE_APP_REGISTRATION_ENABLED;
    },
    /**
     * Returns the translated submit button text.
     */
    submitButtonText() {
      return this.$t('labels.next');
    },
  },
  methods: {
    /**
     * Assigns the entered credentials to the user and on success, authorizes the user.
     */
    assignAccount() {
      this.$v.$touch();
      if (this.$v.userData.$invalid) {
        this.errorCode = null; // hide possible error message
        return;
      }
      this.$http.post(this.$routes.account_assign, this.userData)
        .then(() => {
          this.errorCode = null;
          this.$store.dispatch('authorizeUser');
        })
        .catch((error) => {
          if (error.data && error.data.failureId != null) {
            this.errorCode = error.data.failureId;
            if (this.errorCode
              === this.$config.errors.account_assign_external_id_already_assigned) {
              this.showRestartAd = true;
            }
          } else {
            this.errorCode = this.$config.errors.internal_server_error;
          }
          this.userData.password = '';
          this.userData.username = '';
          this.$v.userData.$reset();
        });
    },
    /**
     * Clears input fields and a potential error message.
     * Sends signal 'showPasswordForgot' to show the PasswordForgot form.
     */
    forgotPassword() {
      this.errorCode = null;
      this.userData.password = '';
      this.userData.username = '';
      this.$emit('showPasswordForgot');
    },
    /**
     * Clears input fields and a potential error message.
     * Sends signal 'showRegister' to show the Register form.
     */
    registerAccount() {
      this.errorCode = null;
      this.userData.ipxxPassword = '';
      this.userData.ipxxUsername = '';
      this.$emit('showRegister');
    },
  },
  validations: {
    userData: {
      password: {
        required,
      },
      username: {
        required,
      },
    },
  },
};
</script>
