<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "account_inactive_error": "Es ist ein Problem mit Ihrem {account}, das mit Ihrem »{providerAccount}« verknüpft ist, aufgetreten.<br>Bitte wenden Sie sich an Ihren Administrator.",
      "azure_login_failed": "Ihr »{provider}« Login ist fehlgeschlagen. Bitte wenden Sie sich an Ihren Administrator oder versuchen Sie es mit einem anderen Konto erneut.",
      "azure_login_required": "Der »{provider}« Login muss wiederholt werden.<br>Bitte klicken Sie auf »{submitButtonText}« und wählen Sie dann Ihren »{providerAccount}« aus.",
      "need_user_consent": "Sie haben dem Service noch nicht zugestimmt. Bitte klicken Sie auf »{submitButtonText}« und bestätigen Sie Ihre Zustimmung. Erst dann ist der Login erfolgreich.",
      "service_not_available": "Dienst derzeit nicht verfügbar, bitte versuchen Sie es später erneut.",
      "tenant_id_not_assigned": "Ihr »{providerAccount}« darf nicht mit my-picturemaxx verbunden werden. Bitte wenden Sie sich an Ihren Administrator oder versuchen Sie es mit einem anderen Konto erneut."
    },
    "en": {
      "account_inactive_error": "There is a problem with your {account}, which is linked to your ‘{providerAccount}’.<br>Please contact your administrator.",
      "azure_login_failed": "Your login into ‘{provider}’ has failed. Please contact your administrator or try again with another account.",
      "azure_login_required": "The ‘{provider}’ login must be repeated.<br>Please click on ‘{submitButtonText}’ and then select your ‘{providerAccount}’.",
      "need_user_consent": "You have not yet agreed to the service. Please click ‘{submitButtonText}’ and confirm your agreement. Only then the login will be successful.",
      "service_not_available": "Service currently not available, please try again later.",
      "tenant_id_not_assigned": "Your ‘{providerAccount}’ must not be connected to my-picturemaxx. Please contact your administrator or try again with another account."
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="ipxx-azure-login px-5">
    <b-row
      v-if="isError"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          variant="danger"
          data-cy="azureErrorMessage"
          v-html="errorMessage"
        />
      </div>
    </b-row>
    <b-row
      v-if="isError"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col text-right">
        <b-btn
          variant="primary"
          data-cy="azureRestartBtn"
          @click="restartAdLogin"
        >
          {{ retryButtonText }}
        </b-btn>
      </div>
    </b-row>
  </div>
</template>

<script>
import restartAdMixin from '@/components/mixins/restartAdMixin';

export default {
  name: 'AzureLogin',
  mixins: [restartAdMixin],
  props: {
    query: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      /**
       * The actual error code when authenticating in azure failed.
       */
      authError: null,
    };
  },
  computed: {
    errorCode() {
      return this.$store.getters.getError;
    },
    errorMessage() {
      if (!this.isError) {
        return '';
      }
      switch (this.errorCode) {
        case this.$config.errors.account_authorize_account_inactive:
          return this.$t('account_inactive_error', {
            account: this.$t('corporate.mypm_account'),
            providerAccount: this.$t('providers.azure_account'),
          });

        case this.$config.errors.account_authorize_external_auth_disabled:
        case this.$config.errors.handle_azure_external_auth_disabled:
          return this.$t('errors.error_external_auth_disabled');

        case this.$config.errors.handle_azure_tenant_error:
          return this.$t('tenant_id_not_assigned', {
            providerAccount: this.$t('providers.azure_account'),
          });

        case this.$config.errors.handle_azure_login_failed:
          return this.$t('azure_login_failed', {
            provider: this.$t('providers.azure'),
          });

        case this.$config.errors.start_oidc_process_failed:
          return this.$t('errors.error_oidc');

        case this.$config.authErrors.azure_login_required:
          return this.$t('azure_login_required', {
            provider: this.$t('providers.azure'),
            providerAccount: this.$t('providers.azure_account'),
            submitButtonText: this.retryButtonText,
          });

        case this.$config.authErrors.azure_access_denied:
        case this.$config.authErrors.azure_interaction_required:
          return this.$t('need_user_consent', {
            submitButtonText: this.retryButtonText,
          });

        case this.$config.authErrors.azure_server_error:
        case this.$config.authErrors.azure_temp_unavailable:
          return this.$t('service_not_available');

        default:
          return this.$t('errors.unknown_error');
      }
    },
    isError() {
      if (this.errorCode === this.$config.errors.account_authorize_assigned_error
        || this.errorCode === this.$config.errors.account_authorize_email_not_verified) {
        return false; // actually no error, so do not show error title
      }
      return this.errorCode !== null;
    },
    retryButtonText() {
      return this.$t('labels.retry');
    },
  },
  mounted() {
    let { query } = this.$route;
    if (this.query !== undefined) {
      query = this.query;
    }
    if (query.error !== undefined) {
      this.$store.dispatch('addAuthError', { code: query.error });
    } else {
      this.$store.dispatch('handleAzureLogin', query);
    }
  },
};
</script>
