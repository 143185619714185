<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "error_sent_failed": "Es konnte keine E-Mail gesendet werden. Bitte versuchen Sie es später erneut.",
      "error_unknown_username": "Bitte überprüfen Sie den eingegebenen Benutzernamen.",
      "error_not_allowed_for_master": "Master Benutzer dürfen ihr Passwort nicht ändern.",
      "intro": "Bitte geben Sie Ihren Benutzernamen ein und klicken Sie auf »{submitButtonText}«, um Ihr Passwort zurückzusetzen.<br>Benutzername vergessen? Bitte kontaktieren Sie unseren Support per E-Mail unter {supportEmail}.",
      "send_now": "Jetzt senden",
      "username": "Benutzername"
    },
    "en": {
      "error_sent_failed": "No email could be sent. Please try again later.",
      "error_unknown_username": "Please check the username you entered.",
      "error_not_allowed_for_master": "Master users are not allowed to change their password.",
      "intro": "Please enter your username and click ‘{submitButtonText}’ to reset your password.<br>Forget your username? Please contact our support team via email at {supportEmail}.",
      "send_now": "Send now",
      "username": "Username"
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="ipxx-password-forgot ipxx-content px-5">
    <h2>{{ $t('titles.reset_password') }}</h2>
    <b-row class="justify-content-center">
      <div class="ipxx-form-responsive-col">
        <p
          v-html="$t('intro', {
            submitButtonText: submitButtonText,
            supportEmail: $t('corporate.support_email')
          })"
        />
      </div>
    </b-row>
    <b-row
      v-if="errorCode"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          :show="errorCode !== null"
          variant="danger"
          data-cy="errorMessage"
          v-html="errorMessage"
        />
      </div>
    </b-row>
    <b-form
      class="my-4"
      @submit.prevent="requestResetPassword"
    >
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          <b-form-group
            label-for="inputUsernameForRequest"
            label-align="left"
            :label="`${$t('username')}*`"
          >
            <b-form-input
              id="inputUsernameForRequest"
              v-model="username"
              autocomplete="username"
              trim
            />
          </b-form-group>
        </div>
      </b-row>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          <b-btn
            type="submit"
            variant="primary"
            class="float-right"
            data-cy="requestPasswordSubmitBtn"
            :disabled="$v.username.$invalid"
          >
            {{ submitButtonText }}
          </b-btn>
          <b-btn
            class="float-left"
            data-cy="goBackBtn"
            @click="goBack"
          >
            {{ $t('labels.back') }}
          </b-btn>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'PasswordForgot',
  mixins: [validationMixin],
  data() {
    return {
      errorCode: null,
      username: '',
    };
  },
  computed: {
    errorMessage() {
      switch (this.errorCode) {
        case this.$config.errors.account_send_password_forgot_already_auth_assigned:
          return this.$t('errors.error_already_auth_assigned', {
            account: this.$t('corporate.mypm_account'),
            providerAccount: this.externalAuthProviderName,
          });
        case this.$config.errors.account_send_password_forgot_not_allowed_for_master:
          return this.$t('error_not_allowed_for_master');
        case this.$config.errors.account_send_password_forgot_mail_failed:
          return this.$t('error_sent_failed');
        case this.$config.errors.account_send_password_forgot_mail_unknown_user:
          return this.$t('error_unknown_username');
        default:
          return this.$t('errors.unknown_error');
      }
    },
    /**
     * Returns the external authentication provider account name.
     */
    externalAuthProviderName() {
      const { authProviderId } = this.$route.params;
      if (authProviderId === this.$config.providers.azure) {
        return this.$t('providers.azure_account');
      }
      return this.$t('providers.default_account');
    },
    submitButtonText() {
      return this.$t('send_now');
    },
  },
  methods: {
    /**
     * Sends signal 'cancel' to cancel requesting to reset password.
     */
    goBack() {
      this.$emit('cancel');
    },
    /**
     * Calls resetPassword route. On success, sends signal 'emailSent'
     * to switch back to assign account.
     */
    requestResetPassword() {
      this.$http.post(this.$routes.password_send_reset_mail, { username: this.username })
        .then((response) => {
          const { data } = response;
          const requestedForUsername = this.username;
          const emailAddress = data.email;

          this.username = ''; // reset model

          this.$emit('emailSent', { username: requestedForUsername, email: emailAddress });
        })
        .catch((error) => {
          if (error.data && error.data.failureId) {
            this.errorCode = error.data.failureId;
          } else {
            this.errorCode = this.$config.errors.internal_server_error;
          }
        });
    },
  },
  validations: {
    username: {
      required,
    },
  },
};
</script>
