export default {
  methods: {
    /**
     * Restarts the AD login so the user can enter new AD credentials.
     */
    restartAdLogin() {
      const id = this.$route.params.authProviderId;
      let prompt;
      switch (this.errorCode) {
        case this.$config.authErrors.azure_access_denied:
        case this.$config.authErrors.azure_interaction_required:
          // user has to consent before logging in
          prompt = 'consent';
          break;
        default:
          // user has to enter his credentials
          prompt = 'login';
      }
      const data = {
        id,
        prompt,
      };
      this.$logger.log('restart login, use prompt', prompt);
      this.$store.dispatch('startLogin', data);
    },
  },
};
