<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "accept_privacy_police": "Mit meiner Registrierung stimme ich der Vearbeitung und Nutzung meiner personenbezogenen Daten gemäß der {0} zu. Diese Einwilligung kann jederzeit und ohne zusätzliche Kosten widerrufen werden.*",
      "accept_terms_condition": "Die {0} habe ich gelesen und akzeptiere diese hiermit.*",
      "basic_data": "Allgemeine Angaben",
      "email": "E-Mail-Adresse",
      "error_already_assigned": "Die Registrierung war nicht erfolgreich. Ihr »{providerAccount}« wurde bereits mit einem {account} verknüpft.<br>Bitte wiederholen Sie die Anmeldung und wählen Sie gegebenfalls ein anderes Konto.",
      "error_unable": "Die Registrierung war nicht erfolgreich, da ein Problem mit der Konfiguration aufgetreten ist. Bitte wenden Sie sich an Ihren Administrator.",
      "first_name": "Vorname",
      "last_name": "Nachname",
      "input_email_info": "Ein Link zum Bestätigen der Registrierung wird an diese Adresse gesendet.",
      "invalid_feedback_conditions": "Bitte akzeptieren Sie die erforderlichen Bedingungen.",
      "invalid_feedback_required": "Bitte geben Sie gültige Daten ein.",
      "invalid_feedback_select": "Bitte wählen Sie eine Option aus.",
      "newsletter": "Newsletter",
      "newsletter_intro": "Gerne informieren wir Sie per E-Mail zu Neuigkeiten rund um den my-picturemaxx.",
      "newsletter_option": "Ja, bitte informieren Sie mich über neue Funktionen.",
      "privacy_policy": "Datenschutzerklärung der picturemaxx AG",
      "privacy_policy_url": "https://www.picturemaxx.com/privacy-policy",
      "salutation": "Anrede",
      "salutations": {
        "mr": "Herr",
        "ms": "Frau"
      },
      "select_option": "Bitte eine Option wählen",
      "system_notifications": "Systembenachrichtigungen",
      "system_notifications_intro": "Sie können sich automatisch darüber informieren lassen, wenn sich administrative Änderungen für Ihre Zugänge ergeben.",
      "system_noti_access_changes": "Änderungen bei einem verbundenen Zugang",
      "system_noti_access_request": "Annahme oder Ablehnung Ihrer Verbindungsanfrage",
      "system_noti_incoming_invitation": "Eingehende Verbindungsanfrage für einen Zugang",
      "system_noti_my_request_answer": "Benachrichtigung über eine neue Antwort in my Requests",
      "terms_conditions": "Allgemeinen Geschäftsbedingungen (AGB) der picturemaxx AG",
      "terms_conditions_title": "AGB und Datenschutzerklärung",
      "terms_conditions_url": "https://www.picturemaxx.com/terms"
    },
    "en": {
      "accept_privacy_police": "I have read and accept {0}.*",
      "accept_terms_condition": "I have read and accept {0}.*",
      "basic_data": "General information",
      "email": "Email address",
      "error_already_assigned": "The registration was not successful. Your ‘{providerAccount}’} has already been linked to a {account}.<br>Please repeat the login and choose another account if necessary.",
      "error_unable": "The registration was not successful because a configuration issue occurred. Please contact your administrator.",
      "first_name": "First name",
      "last_name": "Last name",
      "input_email_info": "A confirmation link for account activation will be sent to this email.",
      "invalid_feedback_conditions": "Please accept the required conditions.",
      "invalid_feedback_required": "This field is required.",
      "invalid_feedback_select": "Please choose an option.",
      "newsletter": "Newsletter",
      "newsletter_intro": "We would be happy to inform you by email about news concerning my-picturemaxx.",
      "newsletter_option": "Yes, please notify me about new features.",
      "privacy_policy": "picturemaxx AG privacy policy",
      "privacy_policy_url": "https://www.picturemaxx.com/privacy-policy?lang=en",
      "salutation": "Salutation",
      "salutations": {
        "mr": "Mr.",
        "ms": "Ms."
      },
      "select_option": "Please select an option",
      "system_notifications": "System notifications",
      "system_notifications_intro": "You can be automatically informed when administrative changes occur for your accesses.",
      "system_noti_access_changes": "Yes, please notify me about any modifications made to my Access IDs.",
      "system_noti_access_request": "Yes, please notify me when my use of an Access ID has been accepted or rejected.",
      "system_noti_incoming_invitation": "Yes, please notify me when an invitation to an Access ID has been sent.",
      "system_noti_my_request_answer": "Yes, please notify me about responses to my Requests.",
      "terms_conditions": "picturemaxx AG general terms and conditions",
      "terms_conditions_title": "General terms and conditions & data protection",
      "terms_conditions_url": "https://www.picturemaxx.com/terms?lang=en"
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div
    v-show="!isLoading"
    class="ipxx-register ipxx-content px-5"
  >
    <h2 v-if="isError">
      {{ $t('titles.error') }}
    </h2>
    <h2 v-else>
      {{ $t('titles.registration') }}
    </h2>
    <b-row
      v-show="isError"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col mt-3">
        <b-alert
          show
          class="text-center"
          variant="danger"
          data-cy="registerErrorMessage"
          v-html="errorMessage"
        />
      </div>
    </b-row>
    <b-row
      v-show="isError"
      class="justify-content-center"
    >
      <div class="ipxx-form-responsive-col">
        <b-btn
          variant="primary"
          class="float-right"
          data-cy="registrationRestartAdLogin"
          @click="restartAdLogin"
        >
          {{ $t('labels.retry') }}
        </b-btn>
      </div>
    </b-row>
    <b-container v-if="!isError">
      <b-form class="text-left my-4">
        <b-row>
          <b-col md>
            <h5>{{ $t('basic_data') }}</h5>
            <b-form-group
              label-for="inputSalutation"
              label-align="left"
              :label="`${$t('salutation')}*`"
              :invalid-feedback="$t('invalid_feedback_select')"
            >
              <b-form-select
                id="inputSalutation"
                v-model="salutationOption"
                :state="!$v.salutationOption.$error && null"
                tabindex="1"
                @input="$v.salutationOption.$touch()"
              >
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('select_option') }}
                  </option>
                </template>
                <!-- These options will appear after the ones from 'options' prop -->
                <option
                  v-for="(salutation, i) in salutations"
                  :key="`salutation_${i}`"
                  :value="salutation"
                >
                  {{ $t(`salutations.${salutation}`) }}
                </option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label-for="inputFirstName"
              label-align="left"
              :label="`${$t('first_name')}*`"
              :invalid-feedback="$t('invalid_feedback_required')"
            >
              <b-form-input
                id="inputFirstName"
                v-model="tempUser.firstname"
                :state="!$v.tempUser.firstname.$error && null"
                tabindex="2"
                trim
                @input="$v.tempUser.firstname.$touch()"
              />
            </b-form-group>
            <b-form-group
              label-for="inputLastName"
              label-align="left"
              :label="`${$t('last_name')}*`"
              :invalid-feedback="$t('invalid_feedback_required')"
            >
              <b-form-input
                id="inputLastName"
                v-model="tempUser.lastname"
                :state="!$v.tempUser.lastname.$error && null"
                tabindex="3"
                trim
                @input="$v.tempUser.lastname.$touch()"
              />
            </b-form-group>
            <b-form-group
              label-for="inputEmail"
              label-align="left"
              :label="`${$t('email')}*`"
              :invalid-feedback="$t('invalid_feedback_required')"
            >
              <b-form-input
                id="inputEmail"
                v-model="tempUser.email"
                :state="!$v.tempUser.email.$error && null"
                tabindex="4"
                trim
                @input="$v.tempUser.email.$touch()"
              />
              <b-form-text id="inputEmailInfo">
                {{ $t('input_email_info') }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col md>
            <div>
              <h5>{{ $t('system_notifications') }}</h5>
              <p>{{ $t('system_notifications_intro') }}</p>
              <div class="mb-2">
                <ipxx-checkbox
                  :model.sync="tempUser.systemNotifications.AccessRequestAnswer"
                  data-cy="accessRequestAnsweredCheckbox"
                  tabindex="5"
                >
                  {{ $t('system_noti_access_request') }}
                </ipxx-checkbox>
              </div>
              <div class="mb-2">
                <ipxx-checkbox
                  :model.sync="tempUser.systemNotifications.AccessChanged"
                  data-cy="accessChangedCheckbox"
                  tabindex="6"
                >
                  {{ $t('system_noti_access_changes') }}
                </ipxx-checkbox>
              </div>
              <div class="mb-2">
                <ipxx-checkbox
                  :model.sync="tempUser.systemNotifications.AccessInvitation"
                  data-cy="accessRequestIncomingCheckbox"
                  tabindex="7"
                >
                  {{ $t('system_noti_incoming_invitation') }}
                </ipxx-checkbox>
              </div>
              <div class="mb-2">
                <ipxx-checkbox
                  :model.sync="tempUser.systemNotifications.MyRequestAnswer"
                  data-cy="myRequestNotificationCheckbox"
                  tabindex="8"
                >
                  {{ $t('system_noti_my_request_answer') }}
                </ipxx-checkbox>
              </div>
            </div>
            <div>
              <h5>{{ $t('terms_conditions_title') }}</h5>
              <div class="mb-2">
                <ipxx-checkbox
                  :model.sync="tempUser.termsAccepted"
                  :error="$v.$dirty && !tempUser.termsAccepted"
                  data-cy="termsCheckbox"
                  tabindex="9"
                >
                  <i18n
                    path="accept_terms_condition"
                    for="terms_conditions"
                    class="pl-1"
                  >
                    <a
                      :href="termsLink"
                      :target="linkTarget"
                      tabindex="13"
                      @click.stop
                    >{{ $t('terms_conditions') }}</a>
                  </i18n>
                </ipxx-checkbox>
              </div>
              <div class="mb-2">
                <ipxx-checkbox
                  :model.sync="tempUser.privacyPolicyAccepted"
                  :error="$v.$dirty && !tempUser.privacyPolicyAccepted"
                  data-cy="privacyCheckbox"
                  tabindex="10"
                >
                  <i18n
                    path="accept_privacy_police"
                    for="privacy_policy"
                    class="pl-1"
                  >
                    <a
                      :href="privacyPolicyLink"
                      :target="linkTarget"
                      tabindex="14"
                      @click.stop
                    >{{ $t('privacy_policy') }}</a>
                  </i18n>
                </ipxx-checkbox>
              </div>
              <div
                v-show="$v.$dirty && !areConditionsAccepted"
                class="mb-2"
                data-cy="invalidFeedbackConditions"
              >
                <p class="invalid-feedback d-block">
                  {{ $t('invalid_feedback_conditions') }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              class="float-right"
              data-cy="registerSubmitBtn"
              tabindex="11"
              @click="createAccount"
            >
              {{ $t('titles.registration') }}
            </b-button>
            <b-button
              class="float-left"
              data-cy="cancelRegisterBtn"
              tabindex="12"
              @click="cancelRegister()"
            >
              {{ $t('labels.cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import restartAdMixin from '@/components/mixins/restartAdMixin';
import { isQWebChannelActive } from '@/services/MypmBridge';
import clone from '../utils/clone';

export default {
  name: 'RegisterForm',
  mixins: [validationMixin, restartAdMixin],
  data() {
    return {
      errorCode: null,
      salutationOption: null,
      salutations: ['mr', 'ms'],
      showRestartAd: false,
      tempUser: {
        email: '',
        firstname: '',
        lastname: '',
        privacyPolicyAccepted: false,
        receiveNewsletter: false,
        termsAccepted: false,
        systemNotifications: {
          AccessChanged: false,
          AccessRequestAnswer: false,
          AccessInvitation: false,
          MyRequestAnswer: false,
        },
      },
      user: {},
    };
  },
  computed: {
    areConditionsAccepted() {
      return this.tempUser.termsAccepted && this.tempUser.privacyPolicyAccepted;
    },
    errorMessage() {
      switch (this.errorCode) {
        case this.$config.errors.account_create_external_auth_disabled:
          return this.$t('error_unable');
        case this.$config.errors.account_create_external_id_already_assigned:
          return this.$t('error_already_assigned', {
            account: this.$t('corporate.mypm_account'),
            providerAccount: this.externalAuthProviderName,
          });
        default:
          return this.$t('errors.unknown_error');
      }
    },
    /**
     * Returns the external authentication provider account name.
     */
    externalAuthProviderName() {
      const { authProviderId } = this.$route.params;
      if (authProviderId === this.$config.providers.azure) {
        return this.$t('providers.azure_account');
      }
      return this.$t('providers.default_account');
    },
    isError() {
      return this.errorCode;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    isMyPmBridgeActive() {
      return isQWebChannelActive();
    },
    /**
     * Returns the target type, depending if the mypm bridge is active or not.
     * @return {string}
     */
    linkTarget() {
      return this.isMyPmBridgeActive ? '_self' : '_blank';
    },
    privacyPolicyLink() {
      let url = this.$t('privacy_policy_url');
      if (this.isMyPmBridgeActive) {
        url = `ext-${url}`;
      }
      return url;
    },
    termsLink() {
      let url = this.$t('terms_conditions_url');
      if (this.isMyPmBridgeActive) {
        url = `ext-${url}`;
      }
      return url;
    },
  },
  mounted() {
    this.user = clone(this.tempUser);
    this.preFillForm();
  },
  methods: {
    /**
     * Sends signal 'cancel' to cancel the registration process.
     */
    cancelRegister() {
      this.$emit('cancel');
    },
    /**
     * Calls the »account create« route. If successful, a message is displayed informing the user
     * to activate the account via email confirmation link.
     */
    createAccount() {
      this.$v.$touch();
      if (this.$v.validationGroup.$invalid || !this.areConditionsAccepted) {
        return;
      }
      this.tempUser.salutation = this.localizedSalutationLabel(this.salutationOption);

      this.$store.commit('setLoading', true);
      this.$http.post(this.$routes.account_create, this.tempUser)
        .then(() => {
          const registrationEmail = this.tempUser.email;
          // starts verify email process
          this.$store.commit('setRegistrationEmail', registrationEmail);

          // Clear temp data.
          this.salutationOption = null;
          this.tempUser = clone(this.user);
          this.$v.tempUser.$reset();
          this.$v.salutationOption.$reset();
        }).catch((error) => {
          this.$store.commit('setLoading', false);
          if (error.data && error.data.failureId != null) {
            this.errorCode = error.data.failureId;
            if (this.errorCode
              === this.$config.errors.account_create_external_id_already_assigned) {
              this.showRestartAd = true;
            }
          } else {
            this.errorCode = this.$config.errors.internal_server_error;
          }
        });
    },
    /**
     * Returns a translated salutation label.
     * @param salutationId
     * @return {string}
     */
    localizedSalutationLabel(salutationId) {
      if (salutationId === 'mr') {
        return this.$t('salutations.mr');
      }
      return this.$t('salutations.ms');
    },
    /**
     * Fill out some input fields with optional received user info.
     */
    preFillForm() {
      const userInfo = this.$store.getters.getUserInfo;
      if (userInfo === null) {
        return;
      }
      if (userInfo.firstname) {
        this.tempUser.firstname = userInfo.firstname;
      }
      if (userInfo.lastname) {
        this.tempUser.lastname = userInfo.lastname;
      }
      if (userInfo.email) {
        this.tempUser.email = userInfo.email;
      }
    },
  },
  validations: {
    salutationOption: {
      required,
    },
    tempUser: {
      email: {
        email,
        required,
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
    },
    validationGroup: ['salutationOption', 'tempUser'],
  },
};
</script>
