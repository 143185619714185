/**
 * Provide a deep cloning mechanism which preserves data types like `Date` or
 * functions and all primitive types. By the way using this function is faster
 * than using `JSON.parse(JSON.stringify())`.
 *
 * @see http://jsben.ch/tPp9i
 */
const clone = (obj) => {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (obj === null || typeof obj !== 'object') return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    const len = obj.length;
    for (let i = 0; i < len; i += 1) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    Object.keys(obj).forEach((attr) => {
      copy[attr] = clone(obj[attr]);
    });
    return copy;
  }

  throw new Error('Unable to copy obj! Its type is not supported.');
};

export default clone;
